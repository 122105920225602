;(function() {

  $.fn.getMaxChildrenHeight = function getMaxChildrenHeight() {
    var max_height = 0;
    var $list = $(this).find('> *');
    $list.each(function(i, node) {
      if ($(node).height() > max_height)
        max_height = $(node).height();
    });
    return max_height;
  }

  $.fn.inheritMaxChildrenHeight = function inheritMaxChildrenHeight() {
    var $self = $(this);
    $self.height($self.getMaxChildrenHeight());
  }

  $.fn.sameHeight = function sameHeight() {
    var max_height = 0;
    var $list = $(this);
    $list.each(function(i, node) {
      if ($(node).height() > max_height)
        max_height = $(node).height();
    });
    $list.each(function(i, node) {
      $(node).height(max_height);
    });
    return $list;
  }

  // Set the banner wrapper height equal to the containing image size
  // This only runs once the child image is loaded
  $.fn.inheritImageDimensions = function inheritImageDimensions(heightElementsSelector, widthElementsSelector) {
    var $self = $(this);
    $self.each(function(i, item) {
      $(item).imagesLoaded(function() {
        var object = this;
        var maxImageHeight = 0;
        var maxImageWidth = 0;
        // Iterate items
        for (var i = 0; i < object.elements.length ; i++) {
          // Iterate slides/images
          for (var j = 0; j < object.images.length ; j++) {
            var image = object.images[j].img;
            // image.scrollHeight > 0 indicates that the image is not hidden
            if (image.height > maxImageHeight && image.scrollHeight > 0) {
              maxImageHeight = image.height;
            }
            // image.scrollWidth > 0 indicates that the image is not hidden
            if (image.width > maxImageWidth && image.scrollWidth > 0) {
              maxImageWidth = image.width;
            }
          }
          var $item = $(object.elements[i]);
          if (typeof heightElementsSelector !== 'undefined') {
            // Set the target element width minus the grid paddings
            var padding_left = parseInt($(widthElementsSelector).css('padding-left'));
            var padding_right = parseInt($(widthElementsSelector).css('padding-right'));
            $(item).find(widthElementsSelector).width(maxImageWidth - (padding_left + padding_right));
            // Set the target element height according the max specified height
          }
          if (typeof heightElementsSelector !== 'undefined') {
            // Set the image height to the selector itself or specified target element(s)
            $(heightElementsSelector).height(maxImageHeight);
          }
          $item.height(maxImageHeight);
        }
      });
    });
  };

  $.fn.scrollAnimate = function scrollAnimate(excludeSelector, callback) {
    var $self = $(this);
    $('html,body').animate({
      scrollTop: $self.offset().top - $(excludeSelector).outerHeight()
    }, 1000, 'easeInOutQuart', callback);
  }

})();